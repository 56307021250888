<template>
	<var-skeleton :rows="7" :loading="loadingServers">
		<var-row>
			<breadcrumbs current-item-title="Create Command" :items="breadcrumbItems" />
		</var-row>
		<var-card
			title="Create Command"
			class="mb-4"
		>
			<template #extra>
				<var-form ref="form" :disabled="loading">
					<var-input
						placeholder="Description"
						:rules="[v => !!v || 'Command description cannot be empty']"
						v-model="formData.description"
						class="mb-3"
					/>
					<var-input
						placeholder="Body"
						:rules="[v => !!v || 'Command body cannot be empty']"
						textarea
						v-model="formData.text"
						class="mb-3"
					/>
					<var-row :gutter="10">
						<var-col :span="12">
							<var-select
								placeholder="Duration"
								:rules="[v => !!v || 'You must select a duration']"
								v-model="formData.duration_id"
								class="mb-3"
							>
								<var-option v-for="duration in durations" :label="duration.text" :value="duration.id"  />
							</var-select>
						</var-col>
						<var-col :span="12">
							<var-select
								placeholder="Execution state"
								:rules="[v => !!v || 'You must select an execution state']"
								v-model="formData.execution_state"
								class="mb-3"
							>
								<var-option v-for="(executionState, key) in executionStates" :label="executionState" :value="key"  />
							</var-select>
						</var-col>
					</var-row>

					<var-row :gutter="10">
						<var-col :span="12">
							<var-select
								placeholder="Frequency"
								:rules="[v => !!v || 'You must select a frequency']"
								v-model="formData.frequency"
								class="mb-3"
							>
								<var-option v-for="(frequency, key) in frequencies" :label="frequency" :value="key"  />
							</var-select>
						</var-col>
						<var-col :span="12">
							<var-select
								placeholder="Servers"
								multiple
								:rules="[v => v.length >= 1 || 'You must select at least one server']"
								v-model="formData.server_ids"
								class="mb-3"
							>
								<var-option v-for="server in servers" :label="server.name" :value="server.id"  />
							</var-select>
						</var-col>
					</var-row>

					<var-button type="primary" @click="createCommand" class="mt-3" :loading="loading">Create</var-button>
				</var-form>
			</template>
		</var-card>
	</var-skeleton>
</template>

<script>
import {reactive, ref} from "vue";
import request from "@/request";
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
	name: "CreateCommand",
	components: {Breadcrumbs},
	props: {
		// project: {
		// 	type: Object,
		// 	required: true
		// }
	},
	created() {
		this.getServers()
	},
	setup() {
		const formData = reactive({
			duration_id: null,
			description: null,
			text: null,
			execution_state: null,
			frequency: null,
			server_ids: []
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
	data() {
		return {
			loading: false,
			loadingServers: false,
			breadcrumbItems: [
				{
					title: 'Projects',
					to: 'Projects',
					params: {}
				}
			],
			durations: null,
			executionStates: null,
			frequencies: null,
			servers: null,
		}
	},
	methods: {
		getServers() {
			this.loadingServers = true;
			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.id + '/command_meta')
				.then(data => {
					this.loadingServers = false

					this.breadcrumbItems.push({
						title: data.project,
						to: 'ProjectDetails',
						params: {
							id: this.$route.params.id
						}
					})

					this.servers = data.servers
					this.durations = data.durations
					this.executionStates = data.execution_states
					this.frequencies = data.frequencies
				})
				.catch(error => {
					this.loadingServers = false
					console.log(error)
				})
		},
		createCommand() {
			this.form.validate().then(validated => {
				if (!validated) {
					return
				}

				this.loading = true
				request.post(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.id + '/commands', this.formData)
					.then(data => {
						console.log(data)
						this.loading = false
						this.$router.push({
							name: 'ProjectDetails',
							params: {
								id: this.$route.params.project_id
							}
						})
					})
					.catch(error => {
						this.loading = false
						alert(error.response.data.message)
					})
			})
		}
	}
}
</script>

<style scoped>

</style>